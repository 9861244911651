import { OrderStatusEnum } from '@dev-fast/types';

export const ORDER_COMPLETE_STATUSES: OrderStatusEnum[] = [OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.COMPLETED];
export const ORDER_CANCELED_STATUSES: OrderStatusEnum[] = [
  OrderStatusEnum.CANCELED_BY_SELLER_BEFORE_TRADE,
  OrderStatusEnum.CANCELED_BY_FASTOFFER,
  OrderStatusEnum.CANCELED_BY_SELLER,
  OrderStatusEnum.CANCELED_BY_BUYER,
  OrderStatusEnum.CANCELED_BY_TIMER,
];
/**@deprecated лежит рядом с OrderStatusEnum  */
export const BID_ON_ORDER_STATUSES: OrderStatusEnum[] = [
  OrderStatusEnum.FIRST_BID,
  OrderStatusEnum.SECOND_BID,
  OrderStatusEnum.THIRD_PLUS_BID,
];
export type PosibleBodyBorderClass = 'lot-success' | 'lot-completed' | 'lot-canceled' | '';
export type PosibleCardTitle = 'PAUSED' | 'ON_AUCTION' | 'WAITING' | 'SEND_TRADE' | 'CANCELED' | 'AUCTION_FINISHED';
export type PosiblePriceStatusLocale =
  | 'P2P_WIDGETS.RENEW_SIDEBAR.SELLER_PRICE'
  | 'P2P_WIDGETS.RENEW_SIDEBAR.FINAL_PRICE'
  | 'P2P_WIDGETS.DEPOSITING.CURRENT_OFFER';
